import { HttpOptionsModel, TableSort } from '@amaris/lib-highway/models';
import { Injectable } from '@angular/core';
import {
  Observable, catchError, map, of,
  switchMap,
  tap,
} from 'rxjs';
import { HttpClientService } from '@amaris/lib-highway/services';
import { ToastrService } from 'ngx-toastr';
import { ApiEndpoints } from '../../../enums/api-endpoints.enum';
import { environment } from '../../../../environments/environment';
import { ConsultationExtended } from '../../../models/consultation-extended.model';
import { ApiResponse } from '../../../models/api-response.model';
import { MovementAmplitude } from '../../../models/movement-amplitude.model';
import { ColorDropdown } from '../../../models/dropdown.model';
import { ActData } from '../../../models/act-data.model';
import { DataStoreService } from '../../data-store/data-store.service';
import { PainZoneExtended } from '../../../models/pain-zone-extended.model';
import { DocumentApiService } from '../document-api/document-api.service';
import { PatientExtended } from '../../../models/patient-extended.model';
import { User } from '../../../models/user.model';
import { ConsultationListItem } from '../../../models/consultations-list-item.model';
import { UsersApiService } from '../users-api/users-api.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultationsApiService {
  constructor(
    private readonly httpClientService: HttpClientService,
    private readonly toastrService: ToastrService,
    private readonly dataStoreService: DataStoreService,
    private readonly documentApiService: DocumentApiService,
    private readonly usersApiService: UsersApiService,
  ) { }

  generateInvoice(consultation: ConsultationExtended, patient: PatientExtended, user: User): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl}/billings`,
      body: {
        paymentMethod: consultation.paymentMethod,
        consultationReason: consultation.consultationReason,
        consultationPrice: consultation.consultationPrice,
        consultationCity: this.dataStoreService.currentMedialPractice$.value?.city,
        consultationDate: consultation.createdAt,
        consultationId: consultation.id,
        companyName: this.dataStoreService.currentMedialPractice$.value?.companyName,
        officeAddress: `${this.dataStoreService.currentMedialPractice$.value?.officeAddress},
          ${this.dataStoreService.currentMedialPractice$.value?.postalCode},
          ${this.dataStoreService.currentMedialPractice$.value?.city}`,
        siret: this.dataStoreService.currentMedialPractice$.value?.siret,
        adeli: user.adeliNumber,
        practitionerFirstName: user.firstname,
        practitionerLastName: user.lastname,
        practitionerEmail: user.email,
        patientFirstName: patient.firstname,
        patientLastName: patient.lastname,
        patientPhoneNumber: patient.phone,
        patientEmail: patient.email,
        patientBillingAddress: patient.address,
        patientBirthDate: patient.birthDate.toISOString(),
        maidenName: patient.maidenName,
        socialSecurityNumber: patient.socialSecurityNumber,
        billingStatus: null,
        practitionerProfession: user.status,
      },
      withCredentials: true,
    };

    return this.httpClientService.post<ApiResponse<any>>(options).pipe(
      map((response) => response.data),
      catchError(() => {
        this.toastrService.error('Une erreur est survenu lors de la création de la facture. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
    // add parenttype filter
    // this.documentApiService.getAllByParentid(consultation.id)
  }

  createConsultation(patientId: string, consultation: ConsultationExtended): Observable<string> {
    consultation.patientId = patientId;

    return this.usersApiService.getConnectedUser().pipe(
      tap((user) => {
        consultation.praticienFirstName = user.data.firstname;
        consultation.praticienLastName = user.data.lastname;
      }),
      switchMap(() => {
        const tmpConsultation = { ...consultation };
        delete tmpConsultation.anamneseRecordings;
        delete tmpConsultation.examsRecordings;
        delete tmpConsultation.actsRecordings;
        const options: HttpOptionsModel = {
          url: environment.apiUrl + ApiEndpoints.Consultations,
          body: tmpConsultation,
          withCredentials: true,
        };

        return this.httpClientService.post<ApiResponse<string>>(options).pipe(
          map((response) => response.data), // extract the data property
        );
      }),
      tap((response) => {
        this.toastrService.success('Consultation enregistrée avec succès');
      }),
      catchError((error) => {
        this.toastrService.error('Une erreur est survenu lors de la création de la consultation. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
  }

  updateConsultation(consultation: ConsultationExtended): Observable<string> {
    const tmpConsultation = { ...consultation };
    delete tmpConsultation.anamneseRecordings;
    delete tmpConsultation.examsRecordings;
    delete tmpConsultation.actsRecordings;
    delete tmpConsultation.checkupRecordings;

    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Consultations}/${consultation.id}`,
      body: tmpConsultation,
      withCredentials: true,
    };

    return this.httpClientService.put<ApiResponse<string>>(options).pipe(
      map((response) => {
        this.toastrService.success('Consultation modifiée avec succès');
        return response.data;
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenu lors de la modification de la consultation. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
  }

  patchConsultation(partialConsultation: Partial<ConsultationExtended>): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Consultations}/${partialConsultation.id}`,
      body: partialConsultation,
      withCredentials: true,
    };

    return this.httpClientService.patch<ApiResponse<string>>(options).pipe(
      map((response) => {
        this.toastrService.success('Consultation modifiée avec succès');
        return response.data;
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenu lors de la modification de la consultation. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
  }

  getConsultationsByPatientId(patientId: string): Observable<any> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Consultations}`,
      params: { patientId },
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<any>>(options).pipe(
      map((response) => {
        const data = response?.data
          ? response.data.sort((a: any, b:any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          : [];
        data.forEach((consultation: any) => {
          consultation.painZones = consultation.painZones.map((zone: any) => new PainZoneExtended(zone));
          consultation.propagatedPainZones = consultation.propagatedPainZones
            .map((list: PainZoneExtended[]) => list.map((zone: PainZoneExtended) => new PainZoneExtended(zone)));
        });
        return data;
      }),
      tap((consultations) => {
        this.dataStoreService.setCurrentPatientExtendedConsultations(consultations);
      }),
    );
  }

  getConsultationTypes(): Observable<ColorDropdown[]> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.ConsultationTypes}`,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<any>>(options).pipe(
      map((response) => response.data.map((type: any) => (
        new ColorDropdown(type.label, type.id, type.colorCode)
      ))),
      tap((types) => {
        this.dataStoreService.setConsultationTypes(types);
      }),
    );
  }

  getConsultationActesData(): Observable<ActData[]> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.ConsultationActs}`,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<ActData[]>>(options).pipe(
      map((response) => response.data),
      tap((actes) => {
        this.dataStoreService.setConsultationActeData(actes);
      }),
    );
  }

  getConsultationById(consultationId: string, skipDocuments: boolean = false): Observable<ConsultationExtended> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Consultations}/${consultationId}`,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<ConsultationExtended>>(options).pipe(
      map((response) => {
        response.data.examsManipulations = response.data.examsManipulations.map((manip) => new MovementAmplitude(
          manip.id,
          manip.icon,
          manip.title,
          manip.value,
          manip.unit,
          null,
          manip.bodyZoneId,
        ));
        response.data.bilanManipulations = response.data.bilanManipulations.map((manip) => new MovementAmplitude(
          manip.id,
          manip.icon,
          manip.title,
          manip.value,
          manip.unit,
          null,
          manip.bodyZoneId,
        ));
        response.data.painZones = response.data.painZones.map((zone) => new PainZoneExtended(zone));

        response.data.propagatedPainZones = response.data.propagatedPainZones.map((list) => list.map((zone) => new PainZoneExtended(zone)));

        if (!skipDocuments) {
          return new ConsultationExtended(response.data, this.documentApiService.getAllByParentid(response.data.id));
        }

        return new ConsultationExtended(response.data);
      }),
    ).pipe(catchError(() => of(null)));
  }

  getPaginatedConsultationsFilteredSorted(
    itemsPerPage: number,
    requestedPage: number,
    sort: TableSort = null,
    filter: string = '',
  ): Observable<ApiResponse<ConsultationListItem[]>> {
    const params: any = {
      'page[limit]': itemsPerPage,
      'page[current]': requestedPage,
    };
    if (sort && sort.sortOrder) {
      params['sort'] = (sort.sortOrder === 'desc' ? '-' : '') + sort.sortBy;
    }
    if (filter) {
      params['query'] = filter;
    }
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Consultations}`,
      params,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<ConsultationListItem[]>>(options).pipe(
      tap((response) => {
        if (response) {
          response.data.forEach((consultation: ConsultationListItem) => {
            consultation.createdAt = new Date(consultation.createdAt);
          });
        }
      }),
    );
  }
}
