import { Observable } from 'rxjs';
import { MovementAmplitude } from './movement-amplitude.model';
import { PainZoneExtended } from './pain-zone-extended.model';
import { Act } from './act.model';
import { EDMDocument } from './edm-document.model';
import { PaymentMethod } from '../enums/payment-method.enum';
import { ConsultationStatus } from '../enums/consultation-status.enum';
import { DocumentFormat } from '../enums/document-format.enum';

export class ConsultationExtended {
    [key: string]: boolean | string | EDMDocument | EDMDocument[]
    | number | Date | PainZoneExtended[] | MovementAmplitude[] | Act[] | any | undefined,

    id: string;
    praticienFirstName: string;
    praticienLastName: string;
    title: string;
    consultationType: string; // should be connected to a list

    painZones?: PainZoneExtended[];

    propagatedPainZones?: PainZoneExtended[][];

    anamneseObservation?: string;
    anamneseRecordings?: EDMDocument[];

    examsManipulations?: MovementAmplitude[];
    examsObservation?: string;
    examsRecordings?: EDMDocument[];

    acts: Act[];
    actsObservation?: string;
    actsRecordings?: EDMDocument[];

    bilanManipulations?: MovementAmplitude[];
    checkupObservation?: string;
    checkupRecordings?: EDMDocument[];

    paymentMethod?: PaymentMethod;
    consultationPrice?: number;
    consultationReason?: string;
    sendInvoiceByMail?: boolean;

    createdAt: Date;
    patientId: string;

    status: ConsultationStatus;
    invoice: EDMDocument;

    constructor(data?: any, documents$?: Observable<EDMDocument[]>) {
      if (data) {
        this.id = data.id;
        this.title = data.title;
        this.consultationType = data.consultationType;
        this.painZones = data.painZones || [];
        this.propagatedPainZones = data.propagatedPainZones || [];
        this.anamneseObservation = data.anamneseObservation;
        this.examsManipulations = data.examsManipulations || [];
        this.examsObservation = data.examsObservation;
        this.acts = data.acts || [];
        this.actsObservation = data.actsObservation;
        this.bilanManipulations = data.bilanManipulations || [];
        this.checkupObservation = data.checkupObservation;
        this.paymentMethod = data.paymentMethod;
        this.consultationPrice = data.consultationPrice;
        this.consultationReason = data.consultationReason;
        this.sendInvoiceByMail = data.sendInvoiceByMail;
        this.createdAt = data.createdAt;
        this.patientId = data.patientId;
        this.status = data.status;
      } else {
        this.painZones = [];
        this.propagatedPainZones = [];
        this.anamneseRecordings = [];
        this.examsRecordings = [];
        this.actsRecordings = [];
        this.checkupRecordings = [];
        this.examsManipulations = [];
        this.bilanManipulations = [];
        this.acts = [];
      }

      if (documents$) {
        documents$.subscribe((documents: EDMDocument[]) => {
          this.anamneseRecordings = documents.filter((doc) => doc.tags.includes('anamnese'));
          this.examsRecordings = documents.filter((doc) => doc.tags.includes('exams'));
          this.actsRecordings = documents.filter((doc) => doc.tags.includes('acts'));
          this.checkupRecordings = documents.filter((doc) => doc.tags.includes('bilan'));
          this.invoice = documents.find((doc) => doc.format === DocumentFormat.PDF);
        });
      } else {
        this.anamneseRecordings = [];
        this.examsRecordings = [];
        this.actsRecordings = [];
        this.checkupRecordings = [];
      }
    }
}
