import { MovementAmplitudeTypes } from '../enums/movement-amplitude-types.enum';

export class MovementAmplitude {
  id: string;
  bodyZoneId: string;

  icon: string;
  title: string;
  value: number;
  unit: string;

  type?: MovementAmplitudeTypes;

  deleted?: boolean;

  constructor(id:string, icon?: string, title?: string, value?: number, unit?: string, type?: MovementAmplitudeTypes, zoneId?: string) {
    this.id = id;
    this.icon = icon;
    this.title = title;
    this.value = value;
    this.unit = unit;
    this.bodyZoneId = zoneId;
    if (type) {
      this.type = type;
    } else {
      this.type = this.value && this.unit ? MovementAmplitudeTypes.Measurement : MovementAmplitudeTypes.Comparison;
    }
  }
}
